<template>
  <q-card>
    <q-card-section class="row items-center">
      <div class="text-h6 text-center q-mr-sm">
        {{ title }}
      </div>

      <search
        dense
        is-expandable
        @submit="handleSearch"
      />

      <q-space />

      <q-btn
        v-if="type === 'modal'"
        color="transparent"
        text-color="dark"
        size="sm"
        icon="close"
        no-caps
        unelevated
        @click="close"
      />

      <div v-else class="text-right">
        <q-btn
          color="dark"
          :label="$t('Assign account')"
          class="q-mr-sm"
          no-caps
          @click="handleAttach"
        />

        <q-btn
          :label="$t('Create new account')"
          color="light-blue-9"
          class="q-mr-sm"
          no-caps
          @click="handleAdd"
        />

        <q-btn
          :color="filterParams && filterParams.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          :label="$t('Filter')"
          no-caps
          unelevated
          @click="openCloseFilters"
        />
      </div>
    </q-card-section>

    <q-card-section v-if="type === 'modal'" class="text-right">
      <q-btn
        color="dark"
        :label="$t('Assign account')"
        class="q-mr-sm"
        no-caps
        @click="handleAttach"
      />

      <q-btn
        :label="$t('Create new account')"
        color="light-blue-9"
        class="q-mr-sm"
        no-caps
        @click="handleAdd"
      />

      <q-btn
        :color="filterParams && filterParams.length > 0 ? 'light-blue-9' : 'dark'"
        text-color="white"
        :label="$t('Filter')"
        no-caps
        unelevated
        @click="openCloseFilters"
      />
    </q-card-section>

    <filter-collapse
      :is-open="isOpenFilter"
      :options="{
        defaultFilter: filterParams,
        fields: activatedFields,
        query: customFilter,
        style: {
          noGroups: true
        }
      }"
      @submit="handleFiltersSubmit"
      @close="openCloseFilters"
    />

    <q-card-section class="text-center">
      <q-table
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="isLoading"
        :filter="filter"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
            showing
            color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
            :props="props"
            class="clickable"
          >
            <q-td
              key="id"
              :props="props"
              class="text-subtitle1"
            >
              <strong>{{ props.row.id }}</strong>
            </q-td>

            <q-td
              key="name"
              :props="props"
            >
              {{ props.row.name }}
            </q-td>

            <q-td
              key="balance"
              :props="props"
              auto-width
            >
              {{ props.row.balance }}

              <span v-if="props.row._embedded.currency">
                {{ props.row._embedded.currency.symbol }}
              </span>
            </q-td>

            <q-td
              key="owner"
              :props="props"
            >
              <span v-if="props.row._embedded.owner">
                {{ props.row._embedded.owner.name }}
              </span>
            </q-td>

            <q-td
              key="created"
              :props="props"
            >
              {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
            </q-td>

            <q-td
              key="actions"
              :props="props"
            >
              <q-btn
                :label="$t('Go to account')"
                color="light-blue-9"
                no-caps
                size="sm"
                class="q-mr-sm"
                @click="handleGoToAccount(props.row)"
              />

              <q-btn
                :label="$t('Cancel')"
                no-caps
                color="negative"
                size="sm"
                @click="handleUnattach(props.row)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>

    <q-dialog v-model="accountModal">
      <q-card>
        <q-card-section>
          <account
            :rate="rate"
            :type="'deposit'"
            :check-for-accounts="accountRequirments"
            @close="handleAccountModal"
            @save="handleAccount"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <account-select-modal
      ref="accountSelectModal"
      @submit="handleSubmit"
      @cancel="handleCancel"
    />
  </q-card>
</template>

<script>
// Vue
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapGetters } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../filters/FilterCollapse.vue'
import AccountSelectModal from '../modals/AccountSelectModal.vue'
import Search from '../search/Search.vue'

export default {
  name: 'AccountsModal',
  emits: ['close'],
  components: {
    Account: defineAsyncComponent(() => import('./Account.vue')),
    Search,
    AccountSelectModal,
    FilterCollapse
  },
  props: {
    type: {
      type: String,
      default () {
        return ''
      }
    },
    title: {
      type: String,
      default () {
        return 'Accounts'
      }
    },
    rate: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      accountRequirments: [
        { type: 'eq', field: 'state', value: 'active' },
        { type: 'eq', field: 'type', value: 'deposit' }
      ],
      isOpen: false,
      isOpenFilter: false,
      isLoading: false,
      items: [],
      accountModal: false,
      filter: '',
      customFilter: {
        users: [
          {
            type: 'innerjoin',
            field: 'roles',
            parentAlias: 'u',
            alias: 'r'
          },
          {
            type: 'eq',
            alias: 'r',
            field: 'id',
            value: 8
          }
        ]
      },
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Balance'),
          name: 'balance',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: '',
          name: 'actions',
          align: 'right'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark text-white'
      },
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'id',
        'created.from',
        'created.to',
        'name',
        'owner'
      ],
      filterParams: []
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  watch: {
    rate () {
      this.onRequest({ pagination: { per_page: 25, page: 1 } })
    }
  },
  mounted () {
    const pagination = {
      per_page: 25,
      page: 1,
      filter: [
        { type: 'eq', field: 'state', value: 'active' }
      ]
    }

    this.onRequest({ pagination })
  },
  methods: {
    handleGoToAccount (account) {
      this.$router.push({ path: '/billing/accounts', query: { page: 1, per_page: 25, forceOpen: true, filter: [{ type: 'eq', field: 'id', value: account.id }] } })
    },
    close () {
      this.$emit('close')
    },
    handleFiltersSubmit (filter) {
      this.onRequest({ pagination: { filter, page: 1 } })
      this.isOpenFilter = false
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    handleSubmit (account) {
      this.items = [account, ...this.items]
    },
    handleCancel (account) {
      this.items = this.items.filter(x => x.id !== account.id)
    },
    handleUnattach (account) {
      return this.$service.accounts.save({ rate: null }, account.id)
        .then(item => {
          this.handleCancel(item)
          return item
        })
    },
    handleAttach () {
      this.$refs.accountSelectModal.open(this.rate)
    },
    handleAccountModal () {
      this.accountModal = false
    },
    handleAccount (account) {
      this.handleSubmit(account)
      this.accountModal = false
    },
    handleAdd () {
      this.accountModal = true
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (!query.per_page) {
        query.per_page = 25
      }

      if (!query.filter) {
        query.filter = []
      }

      if (this.rate && !query.filter.find(x => x.field === 'rate')) {
        query.filter.push({ type: 'eq', field: 'rate', value: this.rate.id })
      }

      if (!query.filter.find(x => x.field === 'state')) {
        query.filter.push({ type: 'in', field: 'state', values: ['active','blocked'] })
      }

      this.filterParams = query.filter
      this.isLoading = true

      return this.$service.accounts.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.items = items

          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
