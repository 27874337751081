<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Billing')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          v-if="isSupervisior || isAdministrator"
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="openCloseFilters"
      />

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="accountRates"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="accountRatesLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th v-if="isSupervisior || isAdministrator" auto-width>
                {{ $t('Accounts') }}
              </q-th>

              <q-th
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
              >
                {{ col.label }}
              </q-th>
            </q-tr>
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td v-if="isSupervisior || isAdministrator" auto-width class="text-center">
                <q-btn
                  size="sm"
                  color="light-blue-9"
                  round
                  dense
                  icon="exposure"
                  @click="props.expand = !props.expand"
                />
              </q-td>

              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                key="type"
                :props="props"
              >
                {{ props.row.type || '--' }}
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>

                <span v-else>
                  --
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-toggle
                  color="light-blue-9"
                  :model-value="props.row.state === 'active'"
                  :disable="isClient && !(isSupervisior || isAdministrator)"
                  @update:model-value="handleStateChange(props.row)"
                />
              </q-td>

              <q-td
                key="actions"
                :props="props"
                style="width: 2%"
              >
                <q-btn
                  size="sm"
                  color="dark"
                  class="q-mr-sm"
                  icon="content_copy"
                  no-caps
                  @click="handleDuplicate(props.row)"
                >
                    <q-tooltip>{{'Copy Rate'}}</q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>

            <q-tr v-if="isSupervisior || isAdministrator" v-show="props.expand" :props="props">
              <q-td colspan="100%" style="padding: 0;">
                <assigned-accounts
                  v-if="props.expand"
                  :title="$t('Accounts')"
                  :rate="props.row"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <account-rate-modal ref="accountRateModal" @submit="handleSubmit" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin.vue'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'
import AssignedAccounts from '../../components/account/AssignedAccounts.vue'
import AccountRateModal from '../../components/modals/AccountRateModal.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'
import _ from "lodash";
import {convertEmbedded, difference} from "@/apps/app/helpers/request-helpers";

export default {
  name: 'AccountRates',
  components: {
    Search,
    FilterCollapse,
    AssignedAccounts,
    AccountRateModal
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: '',
          name: 'actions',
          align: 'right'
        },
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark text-white'
      },
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'state',
        'id',
        'name',
        'created.from',
        'created.to'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isSupervisior',
      'isAdministrator',
      'accountRates',
      'accountRatesNumber',
      'accountRatesLoading',
      'appOptions',
      'isClient'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ' + this.accountRatesNumber)
        : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadAccountRates'
    ]),
    ...mapMutations([
      'setAccountRate',
      'setAccountRates',
      'upsertAccountRate'
    ]),
    handleDuplicate (rate) {
        const query = {
            per_page: 25,
            page: 1,
            filter: [
                { type: 'eq', field: 'rate', value: rate.id },
                { type: 'eq', field: 'type', value: 'managed'},
                { type: 'eq', field: 'state', value: 'active' }
            ]
        }

        this.isLoading = true

        return this.$service.rateModifier.getAll(query)
            .then(({ items }) => {
                const data = {
                    name: `${rate.name}, Copy`,
                    state: rate.state,
                    type: rate.type,
                    currency: rate?._embedded.currency? rate._embedded.currency.id: null,
                    owner: rate?._embedded.owner? rate._embedded.owner.id: null
                }
                return this.$service.accountRate.save(data)
                    .then(accountRate => {
                      return this.saveRateModifiers(accountRate, items)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            })
            .finally(() => {
                this.isLoading = false
            })
    },
    saveRateModifiers (rate, items, results = []) {
        if (items.length <= 0) {
            return Promise.resolve(results)
        }

        const item = items[0]

        let data = {
            code: item.code,
            comment: item.comment,
            event: item.event,
            hasVirtual: item.hasVirtual,
            isUnique: item.isUnique,
            limits: item.limits,
            method: item.method,
            name: item.name,
            object: item.object,
            objectState: item.objectState,
            rate: rate.id,
            settings: item.settings,
            state: item.state,
            type: item.type,
            value: item.value
        }


        return this.$service.rateModifier.save(data)
            .then(item => {
                results.push(item)
                return this.saveRateModifiers(rate, items.slice(1), results)
            })
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleSubmit (item) {
      this.upsertAccountRate(item)
      this.onRowDblClick(item)
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return this.$service.accountRate.save({ state }, item.id)
        .then(rate => {
          const items = this.accountRates.map(item => {
            return item.id === rate.id
              ? rate
              : item
          })

          this.setAccountRates(items)
        })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({})
        .then(({ totalItems }) => {
          if (totalItems <= 0) {
            this.create()
          }
        })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadAccountRates(this.serverParams)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          return { items, totalPages, page, totalItems }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    create () {
      // this.$router.push('/settings/billings/entity')
      this.$refs.accountRateModal.open()
    },
    onRowDblClick (row) {
      if (this.isSupervisior || this.isAdministrator) {
        this.setAccountRate(row)
        this.$router.push('/billing/billings/entity/' + row.id)
      }
    }
  }
}
</script>
