<template>
  <q-dialog v-model="isOpen" class="modal-md">
    <q-card style="overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ accountRate && accountRate.id ? `${$t('Billing account')}: ${accountRate.id}` : $t('New billing') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Close')"
          :disable="isLoading"
          @click="close"
          no-caps
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Ok')"
          :disable="isLoading"
          @click="save"
          no-caps
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AccountRateModal',
  emits: ['submit'],
  data () {
    return {
      isOpen: false,
      isLoading: false,
      accountRate: {
        name: '',
        type: 'default',
        state: 'active',
        currency: ''
      },
      types: [
        { id: 'default', name: this.$t('Default') },
        { id: 'public', name: this.$t('Public') },
        { id: 'private', name: this.$t('Private') }
      ],
      changes: {}
    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.accountRate.name,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                disabled: this.isLoading,
                required: true,
                onChange: name => {
                  this.accountRate.name = name
                  this.changes.name = name
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                value: this.accountRate.type,
                customLabel: (val) => {
                  if (val && typeof val === 'object') {
                    return val.name
                  }

                  return val
                },
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                disabled: this.isLoading,
                required: true,
                options: this.types,
                onChange: (type) => {
                  this.accountRate.type = type && type.id
                  this.changes.type = type && type.id
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Currency'),
                value: this.accountRate.currency ? this.accountRate.currency : '' ,
                required: true,
                hint: this.$t('Select the currency for the billing.'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.name
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.currency.getAll(query)
                },
                onChange: (currency) => {
                  this.accountRate.currency = currency
                  this.changes.currency = currency && currency.id
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    close () {
      this.isOpen = false
    },
    open (accountRate) {
      this.accountRate = accountRate || {
        name: '',
        type: 'default',
        state: 'active'
      }
      this.changes = {}

      this.isOpen = true
    },
    save () {
      this.isLoading = true

      const data = this.accountRate.id
        ? this.changes
        : { ...this.accountRate, owner: this.currentUser.id, currency: this.accountRate.currency.id }

      return this.$service.accountRate.save(data, this.accountRate.id)
        .then(accountRate => {
          this.$emit('submit', accountRate)
          this.close()
          return accountRate
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
